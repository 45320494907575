export enum LocalizationEnum {
  apn_number = 'apn_number',
  referring = 'referring',
  receiving = 'receiving',
  schedule_type = 'schedule_type',
  transaction_type = 'transaction_type',
  retail_center_type = 'retail_center_type',
  quoted_as = 'quoted_as',
  occupier_use_types = 'occupier_use_types',
  target_completion_year = 'target_completion_year',
  target_completion_quarter = 'target_completion_quarter',
  property_year_built = 'property_year_built',
  property_year_renovated = 'property_year_renovated',
  sale_conditions = 'sale_conditions',
  tmi = 'tmi',
  deal_internal_id = 'deal_internal_id',
  deal_pipeline_source_category = 'deal_pipeline_source_category',
  project_transaction_category = 'project_transaction_category',
  project_property_type = 'project_property_type',
  basement_floors = 'basement_floors',
  basement_size = 'basement_size',
  number_of_floors = 'number_of_floors',
  comparable_primary_use = 'comparable_primary_use',
  task = 'task',
  was = 'was',
  ownership_type = 'ownership_type',
  number_of_units = 'number_of_units',
  team = 'team',
  user = 'user',
  loading_bay_length = 'loading_bay_length',
}
